import {
  shiftModalRoutingService,
  ShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { composeCustomFieldsForPayload } from 'components/ShiftModal/src/components/ShiftCustomFields/ShiftCustomFields.utils';
import { IShiftFormCustomField } from 'components/ShiftModal/src/components/ShiftCustomFields/ShiftCustomFields.types';
import { ReasonForEditsType } from 'components/ShiftModal/src/views/ReasonForEdits/ReasonForEdits.types';
import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';

import { defaultApolloClient } from 'providers/ApolloProvider';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { DateTimeType } from 'requests/POST_createOrders.types';
import { API_PUT_updateOrderDetails } from 'requests/PUT_updateOrderDetails';
import { toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import {
  OrderStatusEnum,
  shiftModalOrderDetails_fetchOrder_community_customFields,
} from 'types/graphql-types';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { calculateDuration, convertTimeToHours } from 'utils/time';
import {
  VALIDATION_durationMin1Hr,
  VALIDATION_durationMin1HrNoMessage,
} from 'components/ShiftModal/src/views/EditView/EditView.validation.constants';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import {
  isEditCompletedShiftsFeatureEnabledSelector,
  isEditInProgressShiftsFeatureEnabledSelector,
} from 'store/selectors/featureFlagSelectors';

type EditViewContextType = {
  totalDuration: string;
  totalPaidDuration: string;
  isNotifyFlowRequired: boolean;
  isEditInProgressShift: boolean;
  isEditPostShift: boolean;
};

interface StaticEditViewFormType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  communityCustomFields?: any;
  start_date: string[];
  start_time: string[];
  end_time: string[];
  unpaid_break_time: string[];
  description: string;
  allow_overtime: boolean;
  notification_method?:
    | 'needs_acknowledgement'
    | 'reaccept_or_decline'
    | 'only_notification'
    | 'no_notification';
  reaccept_or_decline_threshold: Array<'4' | '12' | '24' | '48' | '72'>;
  reasonForEdit: ReasonForEditsType[];
  specifiedReasonForEdit?: string;
  zeroHoursWorked?: boolean;
  status: OrderStatusEnum;
  acceptedByAgency?: boolean;
}

export interface EditViewFormType extends StaticEditViewFormType {
  [key: string]: string | string[] | boolean | undefined;
}

export const EditViewContext = createContext({} as EditViewContextType);

export const EditViewProvider: FC = ({ children }) => {
  const { shift_id } = useContext(ShiftModalUIContext);
  const { data } = useShiftModalDataContext();
  const { apiRequest } = useAPIActions();

  const { t } = useTranslation();
  const { timeZone, value: locationId } = getCurrentlySelectedLocation();

  const start_time = DateTime.fromISO(data.fetchOrder!.startAt)
    .setZone(timeZone)
    .toFormat('HH:mm:ss');
  const end_time = DateTime.fromISO(data.fetchOrder!.endAt).setZone(timeZone).toFormat('HH:mm:ss');

  const initialState: StaticEditViewFormType = useMemo(() => {
    return {
      start_time: [start_time],
      end_time: [end_time],
      unpaid_break_time: [data.fetchOrder!.orderSchedule.breakTime],
      start_date: [DateTime.fromISO(data.fetchOrder!.startDate).setZone(timeZone).toString()],
      description: data?.fetchOrder!.description || '',
      notification_method: undefined,
      reaccept_or_decline_threshold: ['24'],
      allow_overtime: data?.fetchOrder?.allowOvertime,
      reasonForEdit: [],
      specifiedReasonForEdit: '',
      zeroHoursWorked: data.fetchOrder.noShow,
      status: data?.fetchOrder?.status?.value,
      acceptedByAgency: data?.fetchOrder?.mainJane?.acceptedByAgency,
      communityCustomFields: data.fetchOrder!.customFields || '',
    };
  }, [data.fetchOrder, end_time, start_time, timeZone]);

  const communityCustomFields = data.fetchOrder.community.customFields;
  const orderCustomFields = data.fetchOrder.customFields;

  const initialStateWithCustomFields = {
    ...initialState,
    ...communityCustomFields?.reduce((acc, customField) => {
      const orderCustomField = orderCustomFields.find(
        (orderCustomField) => orderCustomField.customFieldId === customField.id,
      );
      const isDropdownAndPrefillDefault =
        customField.fieldType === 'dropdown' && customField.prefillDefault;
      const dropdownDefaultOptionValue = customField.customFieldOptions?.length
        ? customField.customFieldOptions[0]?.value || ''
        : '';
      return {
        ...acc,
        [customField.name]: isDropdownAndPrefillDefault
          ? orderCustomField?.value || dropdownDefaultOptionValue
          : orderCustomField?.value || '',
      };
    }, {}),
  };

  const isEditInProgressShiftsFeatureEnabled = useSelector(
    isEditInProgressShiftsFeatureEnabledSelector(Number.parseInt(locationId)),
  );

  const isEditCompletedShiftsFeatureEnabled = useSelector(
    isEditCompletedShiftsFeatureEnabledSelector(Number.parseInt(locationId)),
  );

  const updateOrderDetails = useCallback(
    async (values) => {
      await apiRequest(
        API_PUT_updateOrderDetails(
          payloadTransformer({
            ...values,
            shift_id,
            communityCustomFields,
            biddable: !!data?.fetchOrder?.biddable,
            sentToAgencies: !!data?.fetchOrder?.sentToAgencies,
          }),
        ),
        {
          onSuccess: () => {
            toastService.success(t('success:SHIFT_EDITED'));
            defaultApolloClient.refetchQueries({ include: 'all' });
            queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${shift_id}`);
            window.scheduleUtils.forceDataUpdate();
            shiftModalRoutingService.close();
          },
          onError: handleGenericErrorMessage,
        },
      );
    },
    [
      apiRequest,
      communityCustomFields,
      data?.fetchOrder?.biddable,
      data?.fetchOrder?.sentToAgencies,
      shift_id,
      t,
    ],
  );

  const formUtils = useFormBehaviors<EditViewFormType>({
    initialState: initialStateWithCustomFields,
    validations: {
      end_time: VALIDATION_durationMin1Hr,
      start_time: VALIDATION_durationMin1HrNoMessage,
      unpaid_break_time: VALIDATION_durationMin1HrNoMessage,
    },
    onSubmit: updateOrderDetails,
    isDirtyCheckEnabled: false,
  });

  const _handleChange = useCallback(
    (event) => {
      formUtils.onChange(event);
    },
    [formUtils],
  );

  const totalDuration = calculateDuration(
    formUtils?.values?.start_time[0],
    formUtils?.values?.end_time[0],
    convertTimeToHours(formUtils?.values?.unpaid_break_time[0]),
    'pretty',
    false,
  ) as string;

  const totalPaidDuration = calculateDuration(
    formUtils.values.start_time[0],
    formUtils.values.end_time[0],
    convertTimeToHours(formUtils?.values?.unpaid_break_time[0]),
    'pretty',
    true,
  ) as string;

  const value: EditViewContextType = useMemo(() => {
    const customFieldKeys = communityCustomFields.map((field) => field.name);

    return {
      ...formUtils,
      onChange: _handleChange,
      totalDuration,
      totalPaidDuration,
      isNotifyFlowRequired:
        getIsNotifyFlowRequired(initialState, formUtils.values, customFieldKeys) &&
        !data?.fetchOrder?.biddable,
      isEditInProgressShift:
        isEditInProgressShiftsFeatureEnabled &&
        data?.fetchOrder?.status?.value === OrderStatusEnum.in_progress,
      isEditPostShift:
        isEditCompletedShiftsFeatureEnabled &&
        data?.fetchOrder?.status?.value === OrderStatusEnum.completed,
    };
  }, [
    _handleChange,
    data?.fetchOrder?.biddable,
    data?.fetchOrder?.status?.value,
    formUtils,
    initialState,
    isEditCompletedShiftsFeatureEnabled,
    isEditInProgressShiftsFeatureEnabled,
    totalDuration,
    totalPaidDuration,
    communityCustomFields,
  ]);

  return <EditViewContext.Provider value={value}>{children}</EditViewContext.Provider>;
};

export const useEditViewContext = (): EditViewContextType & UseFormBehaviors<EditViewFormType> =>
  useContext(EditViewContext) as EditViewContextType & UseFormBehaviors<EditViewFormType>;

function getIsNotifyFlowRequired(
  initialState: StaticEditViewFormType,
  values: EditViewFormType,
  customFieldKeys: string[],
) {
  let keysUpdated: string[] = [];
  const keys = Object.keys(initialState) as Array<keyof StaticEditViewFormType>;

  const initialCustomFields = initialState.communityCustomFields || '[]';

  keys.forEach((key) => {
    if (key === 'communityCustomFields') {
      customFieldKeys.forEach((el) => {
        if (values[el]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const initialField = initialCustomFields.find(
            (field: { name: string }) => field.name === el,
          );

          if (initialField?.value !== values[el]) {
            keysUpdated.push(el);
          }
        }
      });
    } else {
      if (Array.isArray(initialState[key])) {
        const initial = initialState[key] as string[];
        const value = values[key] as string[];
        if (initial.sort().join() !== value.sort().join()) keysUpdated.push(key);
      } else {
        if (initialState[key] !== values[key]) keysUpdated.push(key);
      }
    }
  });
  const isNotifyFlowRequired = keysUpdated.some((key) => {
    const keysThatRequireNotifyFlow = [
      'start_time',
      'end_time',
      'unpaid_break_time',
      'start_date',
      'zeroHoursWorked',
      'allow_overtime',
      'description',
      ...customFieldKeys,
    ];
    return keysThatRequireNotifyFlow.includes(key);
  });

  return isNotifyFlowRequired;
}

function payloadTransformer({
  shift_id,
  communityCustomFields,
  ...values
}: EditViewFormType & {
  shift_id: number;
} & {
  communityCustomFields: shiftModalOrderDetails_fetchOrder_community_customFields[];
  biddable: boolean;
}): {
  start_date_time: DateTimeType;
  end_date_time: DateTimeType;
  shift_id: number;
  unpaid_break_time: string;
  description: string;
  notification_method?: string;
  reaccept_or_decline_threshold?: number;
  allow_overtime: boolean;
  reasonForEdit?: [];
  specifiedReasonForEdit?: string;
  zero_hours_worked?: boolean;
  status: OrderStatusEnum;
  acceptedByAgency?: boolean;
} {
  const durationMins = calculateDuration(
    values.start_time[0],
    values.end_time[0],
    values.unpaid_break_time[0],
    'raw',
    false,
  );

  const customFields = composeCustomFieldsForPayload(values, communityCustomFields);

  const [hour, minutes] = values.start_time[0].split(':');
  const _start_time = DateTime.fromISO(values.start_date[0]).set({
    hour: parseInt(hour),
    minute: parseInt(minutes),
  });

  const _end_time = _start_time.plus({ minute: parseInt(`${durationMins}`) });

  const start_date_time = {
    date: _start_time.toFormat('yyyy-MM-dd'),
    hour: _start_time.get('hour'),
    minute: _start_time.get('minute'),
  };

  const end_date_time = {
    date: _end_time.toFormat('yyyy-MM-dd'),
    hour: _end_time.get('hour'),
    minute: _end_time.get('minute'),
  };

  const payload: {
    start_date_time: DateTimeType;
    end_date_time: DateTimeType;
    shift_id: number;
    unpaid_break_time: string;
    description: string;
    notification_method?: string;
    reaccept_or_decline_threshold?: number;
    custom_fields?: IShiftFormCustomField[];
    allow_overtime: boolean;
    zero_hours_worked?: boolean;
    reason_category?: string;
    reason_message?: string;
    status: OrderStatusEnum;
    acceptedByAgency?: boolean;
  } = {
    start_date_time,
    end_date_time,
    shift_id,
    unpaid_break_time: values.unpaid_break_time[0],
    description: values.description,
    notification_method: undefined,
    reaccept_or_decline_threshold: undefined,
    allow_overtime: !!values?.allow_overtime,
    zero_hours_worked: !!values?.zeroHoursWorked,
    reason_category: values?.reasonForEdit[0],
    reason_message: values?.specifiedReasonForEdit,
    status: values?.status,
    acceptedByAgency: values?.acceptedByAgency,
  };

  if (values.biddable) {
    payload.notification_method = 'only_notification';
  } else if (values.notification_method) {
    payload.notification_method = values!.notification_method;
  }

  if (!!values.sentToAgencies) {
    payload.notification_method =
      values.status === OrderStatusEnum.requested || values.status === OrderStatusEnum.pending
        ? 'no_notification'
        : 'only_notification';
  }

  if (
    (values.status === OrderStatusEnum.completed ||
      values.status === OrderStatusEnum.in_progress) &&
    !payload.notification_method
  ) {
    payload.notification_method = 'no_notification';
  }

  if (values.reaccept_or_decline_threshold)
    payload.reaccept_or_decline_threshold = Number.parseInt(
      values!.reaccept_or_decline_threshold[0],
      10,
    );

  payload.custom_fields = customFields;

  return payload;
}
