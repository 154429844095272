import { BKJButton, BKJIcon } from '@bookjane2/bookjane-design-library';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { Flex } from 'components/Flex';
import { ShiftStatusBadge } from 'components/ShiftStatusBadge';
import { StarRating } from 'components/StarRating';
import { Tags } from 'components/Tags';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { JaneName } from 'pages/DashboardPage/JaneName';
import { WarningIcon } from 'pages/SchedulePage/Modals/SchedulePublishModal/styled';
import { Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { MountTransition } from 'routes';
import { queryRefetchService } from 'services/QueryRefetchService';
import { parseVerboseDate } from 'utils';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { withMemo } from 'utils/withMemo';
import {
  CallOutStatus,
  CallOutStatusWrapper,
  JaneNameWrapper,
  ShiftActionsWrapper,
  ShiftCardIcon,
  ShiftCardText,
  MobileShiftCardWrapper,
  MobileShiftCardTopSection,
  ShiftIdWrapper,
  ShiftInfoWrapper,
  BiddingEndsText,
} from 'components/ShiftCardSkeleton/ShiftCardSkeleton.styled';
import { IShiftCardSkeletonProps } from 'components/ShiftCardSkeleton/ShiftCardSkeleton.types';

import { RenderShiftActions } from 'components/RenderShiftActions';
import { ContinueCalloutDialog } from 'components/ContinueCalloutDialog';
import { toastService } from 'services';
import { AllowOvertimeDialog } from 'components/AllowOvertimeDialog';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export const MobileShiftCardSkeleton = withMemo(function (
  props: IShiftCardSkeletonProps,
): JSX.Element | null {
  const { t, i18n } = useTranslation();
  const {
    id,
    bidders,
    biddable,
    biddingTimeEndsAt,
    status,
    mainJane,
    dedicated,
    sentToAgencies,
    acceptedBySharedTeamMember,
    position,
    startAt,
    endAt,
    community,
    isReviewAllowed,
    review,
    recurrence,
    orderSchedule,
    currentlyActiveCalloutList,
    priority,
  } = props.order;

  const {
    shiftActions,
    isShiftInfoWrapperVisible,
    isSharedJanePrimaryCommunityDifferentFromSelectedLocation,
    callOutStatus,
    isAuditTrailEnabled,
    ShiftActionComponent,
    biddingStatus,
    handleCardClick,
    openRatingFlow,
    isContinueCallOutConfirmationOpen,
    isAllowOvertimeConfirmationOpen,
    setIsContinueCallOutConfirmationOpen,
    setIsAllowOvertimeConfirmationOpen,
  } = props;

  const { timeZone } = getCurrentlySelectedLocation();

  return (
    <MountTransition>
      {callOutStatus === 'PAUSED' && currentlyActiveCalloutList?.id && (
        <ContinueCalloutDialog
          orderId={id}
          isContinueCallOutConfirmationOpen={isContinueCallOutConfirmationOpen}
          setIsContinueCallOutConfirmationOpen={setIsContinueCallOutConfirmationOpen}
          onSuccess={() => {
            toastService.success(t('dashboard:CALL_OUT_SUCCESSFUL_RESUMED'));
            queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${id}`);
            window.scheduleUtils.forceDataUpdate();
          }}
        />
      )}
      <AllowOvertimeDialog
        orderId={id}
        isAllowOvertimeConfirmationOpen={isAllowOvertimeConfirmationOpen}
        setIsAllowOvertimeConfirmationOpen={setIsAllowOvertimeConfirmationOpen}
        onSuccess={() => {
          toastService.success(t('dashboard:ALLOW_OVERTIME_SUCCESS'));
          queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${id}`);
          window.scheduleUtils.forceDataUpdate();
        }}
      />
      <MobileShiftCardWrapper className="ShiftCard">
        <MobileShiftCardTopSection onClick={handleCardClick} data-cy={`ShiftCard-${status.value}`}>
          <Flex justifyContent="space-between" childMarginBottom="8px" width="100%">
            <ShiftIdWrapper>{`${t('dashboard:SHIFT_NUMBER')}${orderSchedule.id}`}</ShiftIdWrapper>
            <ShiftStatusBadge status={status.value}>
              {getTranslatedResponse(status?.name, 'status', i18n, t)}
            </ShiftStatusBadge>
          </Flex>
          <Flex justifyContent="space-between" childMarginBottom="8px" width="100%">
            <ShiftCardText data-cy="ShiftCard-PositionName">{position?.name}</ShiftCardText>
          </Flex>

          <ShiftInfoWrapper
            isVisible={isShiftInfoWrapperVisible}
            flexDirection="column"
            childMarginBottom="8px"
          >
            <Flex alignItems="center">
              <ShiftCardIcon iconName="ShiftsDate" color="AccentBrand" fontSize={24} />
              <ShiftCardText>{parseVerboseDate([startAt, endAt], timeZone)}</ShiftCardText>
              {recurrence !== null && (
                <Fragment>
                  &nbsp;
                  <ShiftCardIcon iconName="Recurring" fontSize={24} />
                </Fragment>
              )}
            </Flex>
            <Flex alignItems="center">
              <ShiftCardIcon iconName="ShiftsLocation" color="AccentBrand" fontSize={24} />
              <ShiftCardText>{community?.name}</ShiftCardText>
            </Flex>
            <Flex flexDirection="column" childMarginBottom="8px" css="overflow: hidden;">
              <JaneNameWrapper>
                <JaneAvatar
                  size={28}
                  status={status.value}
                  url={mainJane?.avatarUrls.large}
                  pendingSignup={mainJane?.pendingSignup ?? false}
                />
                <JaneName mainJane={mainJane} status={status.name} />
                {isSharedJanePrimaryCommunityDifferentFromSelectedLocation && (
                  <Fragment>
                    <BKJIcon iconName="Group2" width={16} height={14} />
                  </Fragment>
                )}
                <Tags
                  id={id}
                  status={status.value}
                  isRecurring={!!recurrence}
                  isDedicated={dedicated}
                  isSentToAgencies={sentToAgencies}
                  hasJane={!!mainJane}
                  janePrimaryCommunity={mainJane?.primaryCommunity}
                  isAgencyJane={!!mainJane?.agencyCommunity}
                  isAcceptedBySharedTeamMember={!!acceptedBySharedTeamMember}
                  biddable={biddable}
                  bidders={bidders}
                  biddingTimeEndsAt={biddingTimeEndsAt}
                />
              </JaneNameWrapper>
              <ShiftCardText data-cy="ShiftCard-PositionName">{position?.name}</ShiftCardText>
              {biddingStatus === 'BIDDING' && (
                <BiddingEndsText priority={priority}>
                  {t('dashboard:BIDDING_ENDS')}:&nbsp;
                  {DateTime.fromISO(biddingTimeEndsAt)
                    .setZone(String(timeZone))
                    .toFormat("EEE, MMM d 'at' h:mm a")}
                </BiddingEndsText>
              )}
              {!biddable &&
                isAuditTrailEnabled &&
                (callOutStatus === 'COMPLETE' ? (
                  <CallOutStatusWrapper>
                    <CallOutStatus>{t('dashboard:CALL_OUT_COMPLETE')}</CallOutStatus> &nbsp;
                    <WarningIcon />
                  </CallOutStatusWrapper>
                ) : (
                  callOutStatus === 'PAUSED' && (
                    <CallOutStatusWrapper>
                      <CallOutStatus>{t('dashboard:CALL_OUT_PAUSED')}</CallOutStatus>
                      <BKJIcon iconName="Pause" />
                    </CallOutStatusWrapper>
                  )
                ))}
            </Flex>
          </ShiftInfoWrapper>
        </MobileShiftCardTopSection>
        {isShiftInfoWrapperVisible && (
          <ShiftActionsWrapper
            childMarginBottom="8px"
            padding="8px 0 0"
            onClick={(e) => e.stopPropagation()}
          >
            {isReviewAllowed ? (
              <UserGuard
                userType={[
                  UserPermissionEnum.USER_TYPE_B2B,
                  UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
                  UserPermissionEnum.USER_TYPE_ESSENTIAL_SECONDARY,
                ]}
              >
                <BKJButton variant="PurpleSolid" width="100%" onClick={openRatingFlow}>
                  {t('common:ADD_RATING')}
                </BKJButton>
              </UserGuard>
            ) : review?.rating ? (
              <Flex justifyContent="center" alignItems="center" width="100%">
                <StarRating rating={review?.rating} />
              </Flex>
            ) : null}
            <UserGuard
              userType={[
                UserPermissionEnum.USER_TYPE_B2B,
                UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
                UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
              ]}
            >
              {!!shiftActions.length && (
                <RenderShiftActions
                  variant="PurpleSolid"
                  shiftActions={shiftActions}
                  SingleItemComponent={ShiftActionComponent}
                />
              )}
            </UserGuard>
          </ShiftActionsWrapper>
        )}
      </MobileShiftCardWrapper>
    </MountTransition>
  );
});
