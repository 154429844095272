import { Flex } from 'components/Flex';

import { DeleteButton } from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView.styled';
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { useSelector } from 'react-redux';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { IDetailViewFooterProps } from 'components/ShiftModal/src/views/DetailView/DetailView.types';
import { isEditCompletedShiftsFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { OrderStatusEnum } from 'types/graphql-types';
import { UnableToEditMessage } from 'components/ShiftModal/src/views/DetailView/UnableToEditMessage';

export function WebDetailViewFooter(props: IDetailViewFooterProps) {
  const {
    data,
    openConfirmAvailabilityModal,
    agencyCommunityId,
    isUserAgencyAndAgencyLocationFilterForcedDisabled,
    goToSendToAgenciesView,
    goToReleaseShiftView,
    goToEditView,
    goToDeleteView,
  } = props;

  const { t } = useTranslation();

  const { value: locationId } = getCurrentlySelectedLocation();

  const isEditCompletedShiftsFeatureEnabled = useSelector(
    isEditCompletedShiftsFeatureEnabledSelector(Number.parseInt(locationId)),
  );

  const isCompletedShiftNotEditable =
    data.fetchOrder?.status?.value === OrderStatusEnum.completed &&
    !data.fetchOrder?.isEditable &&
    isEditCompletedShiftsFeatureEnabled;

  return (
    <Fragment>
      <UserGuard
        userType={['USER_TYPE_B2B', 'USER_TYPE_PREMIUM_SECONDARY', 'USER_TYPE_ESSENTIAL_SECONDARY']}
      >
        {!!data.fetchOrder?.isEditable && (
          <Flex>
            <DeleteButton variant="RedText" onClick={goToDeleteView}>
              {t('shift_modal:DELETE')}
            </DeleteButton>
          </Flex>
        )}
      </UserGuard>

      <Flex width="100%" justifyContent="flex-end" gap="8px">
        <UnableToEditMessage />
        {(!!data.fetchOrder?.isEditable || isCompletedShiftNotEditable) && (
          <BKJButton
            width="180px"
            variant="PurpleOutlined"
            onClick={goToEditView}
            disabled={!data.fetchOrder?.isEditable}
          >
            {t('shift_modal:EDIT')}
          </BKJButton>
        )}
        {data.fetchOrder!.isJaneReleaseAllowed &&
          data.fetchOrder?.status?.value !== OrderStatusEnum.in_progress && (
            <BKJButton width="180px" variant="PurpleSolid" onClick={goToReleaseShiftView}>
              {t('shift_modal:RELEASE')}
            </BKJButton>
          )}
        <UserGuard
          userType={[
            UserPermissionEnum.USER_TYPE_B2B,
            UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
            UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
          ]}
        >
          {data.fetchOrder!.canSendToAgency && (
            <BKJButton width="185px" variant="PurpleSolid" onClick={goToSendToAgenciesView}>
              {t('shift_modal:SEND_TO_AGENCY')}
            </BKJButton>
          )}
        </UserGuard>
        <UserGuard
          userType={[
            UserPermissionEnum.USER_TYPE_AGENCY,
            UserPermissionEnum.USER_TYPE_AGENCY_PRIMARY,
            UserPermissionEnum.USER_TYPE_AGENCY_SECONDARY,
          ]}
        >
          {data.fetchOrder?.canAssign &&
            (!isUserAgencyAndAgencyLocationFilterForcedDisabled ? !!agencyCommunityId : true) && (
              <BKJButton width="180px" variant="PurpleSolid" onClick={openConfirmAvailabilityModal}>
                {t('shift_modal:ASSIGN')}
              </BKJButton>
            )}
        </UserGuard>
      </Flex>
    </Fragment>
  );
}
