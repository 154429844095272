import { BKJButton } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { DeleteButton } from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView.styled';
import { Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { useSelector } from 'react-redux';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { isEditCompletedShiftsFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { OrderStatusEnum } from 'types/graphql-types';
import { IAuditTrailFooterProps } from 'components/ShiftModal/src/views/DetailView/AuditTrailDetailView/AuditTrail.types';
import { UnableToEditMessage } from 'components/ShiftModal/src/views/DetailView/UnableToEditMessage';

export function MobileAuditTrailDetailTabViewFooter(props: IAuditTrailFooterProps) {
  const { t } = useTranslation();
  const {
    data,
    goToDeleteView,
    goToEditView,
    goToReleaseShiftView,
    goToSendToAgenciesView,
    goToAwardTeamMemberView,
    openConfirmAvailabilityModal,
    agencyCommunityId,
    isUserAgencyAndAgencyLocationFilterForcedDisabled,
    biddingStatus,
  } = props;

  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const { value: locationId } = getCurrentlySelectedLocation();

  const isEditCompletedShiftsFeatureEnabled = useSelector(
    isEditCompletedShiftsFeatureEnabledSelector(Number.parseInt(locationId)),
  );

  const isCompletedShiftNotEditable =
    data.fetchOrder?.status?.value === OrderStatusEnum.completed &&
    !data.fetchOrder?.isEditable &&
    isEditCompletedShiftsFeatureEnabled;

  if (isReadOnly) return <Fragment />;
  return (
    <BKJSideModalFooter>
      <Flex justifyContent="space-between" width="100%" flexDirection="column" padding="0 0 10px 0">
        {data.fetchOrder!.isJaneReleaseAllowed &&
          biddingStatus !== 'AWARDED' &&
          data.fetchOrder?.status?.value !== OrderStatusEnum.in_progress && (
            <BKJButton
              width="100%"
              variant="NoStyles"
              padding="22px"
              onClick={goToReleaseShiftView}
            >
              {t('shift_modal:RELEASE')}
            </BKJButton>
          )}
        <UserGuard
          userType={[
            UserPermissionEnum.USER_TYPE_B2B,
            UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
            UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
          ]}
        >
          {data.fetchOrder!.canSendToAgency && !data.fetchOrder.biddable && (
            <BKJButton
              width="100%"
              padding="22px"
              variant="PurpleSolid"
              onClick={goToSendToAgenciesView}
            >
              {t('shift_modal:SEND_TO_AGENCIES')}
            </BKJButton>
          )}
        </UserGuard>
        <UserGuard
          userType={[
            UserPermissionEnum.USER_TYPE_AGENCY,
            UserPermissionEnum.USER_TYPE_AGENCY_PRIMARY,
            UserPermissionEnum.USER_TYPE_AGENCY_SECONDARY,
          ]}
        >
          {data.fetchOrder?.canAssign &&
            (!isUserAgencyAndAgencyLocationFilterForcedDisabled ? !!agencyCommunityId : true) && (
              <BKJButton
                width="100%"
                variant="PurpleSolid"
                padding="22px"
                onClick={openConfirmAvailabilityModal}
              >
                {t('shift_modal:ASSIGN')}
              </BKJButton>
            )}
        </UserGuard>
        <Flex width="100%" justifyContent="center" gap="48px" margin="20px 0 0">
          {(!!data.fetchOrder?.isEditable || isCompletedShiftNotEditable) && (
            <BKJButton
              width="auto"
              variant="NoStyles"
              onClick={goToEditView}
              disabled={!data.fetchOrder?.isEditable}
            >
              {t('shift_modal:EDIT')}
            </BKJButton>
          )}
          <UserGuard
            userType={[
              'USER_TYPE_B2B',
              'USER_TYPE_PREMIUM_SECONDARY',
              'USER_TYPE_ESSENTIAL_SECONDARY',
            ]}
          >
            {!!data.fetchOrder?.isEditable && (
              <DeleteButton variant="RedText" onClick={goToDeleteView}>
                {t('shift_modal:DELETE')}
              </DeleteButton>
            )}
          </UserGuard>
          <UserGuard
            userType={[
              UserPermissionEnum.USER_TYPE_B2B,
              UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
              UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
            ]}
          >
            {data.fetchOrder?.biddable && biddingStatus === 'AWARDING' && (
              <BKJButton width="180px" variant="PurpleSolid" onClick={goToAwardTeamMemberView}>
                {t('shift_modal:AWARD')}
              </BKJButton>
            )}
          </UserGuard>
        </Flex>
        <UnableToEditMessage />
      </Flex>
    </BKJSideModalFooter>
  );
}
