import { FormType } from 'hooks/useGroupedFormsBehaviors/useGroupedFormsBehaviors.types';
import {
  CreateShiftFormValuesType,
  ICreateShift,
} from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import {
  CreateShiftBodyType,
  DateTimeTupleType,
  OrderScheduleAttributesType,
  RecurringAttributesType,
} from 'requests/POST_createOrders.types';
import {
  ISO8601Type,
  FormValidationsType,
  FormValuesType,
  ISO8601TupleType,
  FormValidationArgsType,
} from 'types/common.types';
import { castStringToInteger, transformObjectValuesArrayToSingle } from 'utils';
import { calculateShiftDurationInMinutes, convertTimeToHours } from 'utils/time';
import { v4 as uuid } from 'uuid';
import { RecurringWeekDayType } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/CreateShiftModal.types';
import { recurringDayOfWeekMap } from 'components/BKJDaysOfWeekInput/BKJDaysOfWeekInput.constants';
import { createShiftsCardErrorMessages } from './CreateShiftsPage.constants';
import { isRecurring } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.utils';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export const getCreateShiftValidations = (): FormValidationsType => {
  return {
    position_id: ({ formValues, value }: FormValidationArgsType & { value: string[] }) => {
      const _value = value[0];
      let errors = [];
      if (!_value) {
        errors.push(createShiftsCardErrorMessages.POSITION_REQUIRED());
      }
      return { valFieldErrors: errors };
    },
    startDate: ({ formValues, value }: FormValidationArgsType & { value: ISO8601Type[] }) => {
      const _value = value;
      let formErrors = [];
      let fieldErrors = [];
      if (!_value) {
        fieldErrors.push(createShiftsCardErrorMessages.SHIFT_DATE_REQUIRED());
      } else {
        const { startDate, startAt, endAt } = transformObjectValuesArrayToSingle(formValues);
        const [startTime] = getShiftStartTimeAndEndTime({
          startDate: _value,
          startAt,
          endAt,
        });

        const { timeZone } = getCurrentlySelectedLocation();

        const timeStamp = DateTime.fromISO(startDate)
          .setZone(timeZone)
          .set({
            hour: DateTime.fromISO(startTime).get('hour'),
            minute: DateTime.fromISO(startTime).get('minute'),
            day: DateTime.fromISO(startDate).get('day'),
          });

        if (!!_value && timeStamp < DateTime.now().setZone(timeZone)) {
          formErrors.push(createShiftsCardErrorMessages.SHIFT_START_TIME_IN_PAST());
        }
      }

      return { valFormErrors: formErrors, valFieldErrors: fieldErrors };
    },
    startAt: ({ formValues, value }: FormValidationArgsType & { value: string[] }) => {
      const _value = value[0];
      let formErrors = [];
      let fieldErrors = [];
      const { startDate, endAt, unpaidBreakTime } = transformObjectValuesArrayToSingle(formValues);
      const [startTime] = getShiftStartTimeAndEndTime({
        startDate,
        startAt: _value,
        endAt,
      });

      // CANNOT BE IN THE PAST
      if (!!startDate) {
        const { timeZone } = getCurrentlySelectedLocation();
        const timeStamp = DateTime.fromISO(startDate)
          .setZone(timeZone, { keepLocalTime: true })
          .set({
            hour: DateTime.fromISO(startTime).get('hour'),
            minute: DateTime.fromISO(startTime).get('minute'),
          });
        if (timeStamp < DateTime.now().setZone(timeZone)) {
          fieldErrors.push(createShiftsCardErrorMessages.SHIFT_START_TIME_IN_PAST());
          formErrors.push(createShiftsCardErrorMessages.SHIFT_START_TIME_IN_PAST());
        }
      }

      // PAID DURATION LOW
      if (
        calculateShiftDurationInMinutes({
          startAt: _value,
          endAt,
          unpaidBreakTime,
          isPaidDuration: true,
        }) < 60
      ) {
        fieldErrors.push(createShiftsCardErrorMessages.PAID_DURATION_LOW());
        formErrors.push(createShiftsCardErrorMessages.PAID_DURATION_LOW());
      }
      // BREAK TIME LONGER THAN DURATION

      if (
        calculateShiftDurationInMinutes({
          startAt: _value,
          endAt,
          unpaidBreakTime,
          isPaidDuration: false,
        }) <=
        convertTimeToHours(unpaidBreakTime) * 60
      ) {
        fieldErrors.push(createShiftsCardErrorMessages.BREAK_TIME_LONGER_THAN_DURATION());
        formErrors.push(createShiftsCardErrorMessages.BREAK_TIME_LONGER_THAN_DURATION());
      }

      return { valFormErrors: formErrors, valFieldErrors: fieldErrors };
    },
    endAt: ({ formValues, value }: FormValidationArgsType & { value: string[] }) => {
      const _value = value[0];
      let formErrors = [];
      let fieldErrors = [];
      const { startAt, unpaidBreakTime } = transformObjectValuesArrayToSingle(formValues);

      if (
        calculateShiftDurationInMinutes({
          startAt,
          endAt: _value,
          unpaidBreakTime,
          isPaidDuration: true,
        }) < 60
      ) {
        fieldErrors.push(createShiftsCardErrorMessages.PAID_DURATION_LOW());
        formErrors.push(createShiftsCardErrorMessages.PAID_DURATION_LOW());
      }

      if (
        calculateShiftDurationInMinutes({
          startAt,
          endAt: _value,
          unpaidBreakTime,
          isPaidDuration: false,
        }) <=
        convertTimeToHours(unpaidBreakTime) * 60
      ) {
        fieldErrors.push(createShiftsCardErrorMessages.BREAK_TIME_LONGER_THAN_DURATION());
        formErrors.push(createShiftsCardErrorMessages.BREAK_TIME_LONGER_THAN_DURATION());
      }

      return { valFormErrors: formErrors, valFieldErrors: fieldErrors };
    },
    unpaidBreakTime: ({ formValues, value }: FormValidationArgsType & { value: string[] }) => {
      const _value = value[0];
      let formErrors = [];
      let fieldErrors = [];
      const { startAt, endAt } = transformObjectValuesArrayToSingle(formValues);

      if (!_value) {
        fieldErrors.push(createShiftsCardErrorMessages.UNPAID_BREAK_TIME_REQUIRED());
      }

      if (
        calculateShiftDurationInMinutes({
          startAt,
          endAt,
          unpaidBreakTime: _value,
          isPaidDuration: true,
        }) < 60
      ) {
        fieldErrors.push(createShiftsCardErrorMessages.PAID_DURATION_LOW());
        formErrors.push(createShiftsCardErrorMessages.PAID_DURATION_LOW());
      }

      if (
        calculateShiftDurationInMinutes({
          startAt,
          endAt,
          unpaidBreakTime: _value,
          isPaidDuration: false,
        }) <=
        convertTimeToHours(_value) * 60
      ) {
        fieldErrors.push(createShiftsCardErrorMessages.BREAK_TIME_LONGER_THAN_DURATION());
        formErrors.push(createShiftsCardErrorMessages.BREAK_TIME_LONGER_THAN_DURATION());
      }

      return { valFormErrors: formErrors, valFieldErrors: fieldErrors };
    },
  };
};

export const getInitialCreateShiftForm = ({
  startDate,
  id,
  isUserEssential,
}: {
  startDate?: ISO8601Type;
  id?: string;
  isUserEssential?: boolean;
}): ICreateShift => {
  return {
    values: {
      id: id || uuid(),
      quantity: ['1'],
      position_id: [],
      startDate: startDate || DateTime.now().startOf('day').toString(),
      startAt: ['07:00:00'],
      endAt: ['15:00:00'],
      unpaidBreakTime: [''],
      description: '',
      recurring_frequency: [],
      recurring_interval: [],
      recurring_expiration_date: '',
      recurring_day: 0,
      request_janes: '',
      estimatedTotalPayout: 0,
      recurring_weekday: [],
      customFieldsFormValues: {},
      ...(isUserEssential && {
        min_rate: '',
        max_rate: '',
      }),
    },
    dirtyFields: ['startAt', 'endAt', 'unpaidBreakTime'],
    validations: getCreateShiftValidations(),
    requiredFields: ['quantity', 'position_id', 'startDate', 'startAt', 'endAt', 'unpaidBreakTime'],
    onChange: () => {},
  };
};

export const getInitialCreateShiftsPageForm = ({
  initialValues,
}: {
  initialValues?: FormValuesType;
}): FormType => {
  return {
    values: { ...initialValues },
    requiredFields: ['location'],
    dirtyFields: [],
  };
};

export const getHourAndMinute = (hourAndMinString: string): number[] => {
  return hourAndMinString.split(':').map((str: string) => parseInt(str));
};

export const getShiftStartTimeAndEndTime = (args: {
  startDate?: ISO8601Type | ISO8601Type[];
  startAt: string | string[];
  endAt: string | string[];
}): ISO8601TupleType => {
  const { startDate, startAt, endAt } = transformObjectValuesArrayToSingle(args);
  const _startDate = startDate ? DateTime.fromISO(startDate) : DateTime.now();
  const [startHour, startMinute] = getHourAndMinute(startAt);
  const [endHour, endMinute] = getHourAndMinute(endAt);
  const start_time = _startDate.set({ hour: startHour, minute: startMinute });

  let end_time = _startDate.set({ hour: endHour, minute: endMinute });

  if (start_time >= end_time) {
    end_time = end_time.plus({ days: 1 });
  }

  return [String(start_time.toISO()), String(end_time.toISO())];
};

export const getShiftStartDateTimeAndEndDateTime = ({
  startDate,
  startAt,
  endAt,
}: {
  startDate?: ISO8601Type | ISO8601Type[];
  startAt: string | string[];
  endAt: string | string[];
}): DateTimeTupleType => {
  const [start_time, end_time] = getShiftStartTimeAndEndTime({
    startDate,
    startAt,
    endAt,
  });

  const startTime = DateTime.fromISO(start_time);
  const endTime = DateTime.fromISO(end_time);

  const start_date_time = {
    date: startTime.toFormat('YYYY-MM-DD'),
    hour: startTime.hour,
    minute: startTime.minute,
  };

  const end_date_time = {
    date: endTime.toFormat('YYYY-MM-DD'),
    hour: endTime.hour,
    minute: endTime.minute,
  };

  return [start_date_time, end_date_time];
};

export const recurringWeekDayTransformer = (recurring_weekday: RecurringWeekDayType[]): number => {
  const sum = recurring_weekday
    .map((day: string) => {
      return recurringDayOfWeekMap[day];
    })
    .reduce((acc, dayNumber) => {
      return acc + dayNumber;
    });
  return sum;
};

export const shiftPayloadTransformer = ({
  shift,
  isUserEssential,
}: {
  shift: CreateShiftFormValuesType;
  isUserEssential?: boolean;
}): CreateShiftBodyType => {
  const {
    recurring_expiration_date,
    recurring_weekday,
    description,
    request_janes,
    dedicated,
    allow_overtime,
    custom_fields,
  } = shift;

  const isAllowOvertime = isRecurring(shift) ? false : allow_overtime;

  const {
    recurring_frequency,
    recurring_interval,
    position_id,
    startDate,
    startAt,
    endAt,
    quantity,
    unpaidBreakTime,
    min_rate,
    max_rate,
  } = transformObjectValuesArrayToSingle(shift);

  let recurring_day = 0;
  if (recurring_frequency === 'month') {
    recurring_day = Number(DateTime.fromISO(startDate).daysInMonth);
  }

  const [start_date_time, end_date_time] = getShiftStartDateTimeAndEndDateTime({
    startDate,
    startAt,
    endAt,
  });

  const order_schedules_attributes: OrderScheduleAttributesType[] = [
    ...Array(parseInt(quantity)),
  ].map((_, index) => {
    const request_janes_split = request_janes?.split(',').filter((id) => id !== '');
    return {
      start_date_time,
      end_date_time,
      break_time: unpaidBreakTime,
      ...(request_janes_split &&
        request_janes_split[index] && { request_janes: [request_janes_split[index]] }),
    };
  });

  const recurring_attributes: RecurringAttributesType = {
    ...(recurring_frequency &&
      recurring_interval && {
        frequency: recurring_frequency,
        interval: castStringToInteger(recurring_interval),
      }),
    ...(recurring_expiration_date && { expiration_date: recurring_expiration_date }),
    ...(recurring_weekday[0] && { weekdays: recurringWeekDayTransformer(recurring_weekday) }),
    ...(recurring_day && { day: recurring_day }),
  };

  return {
    position_id: position_id,
    description,
    allow_overtime: isAllowOvertime,
    order_schedules_attributes,
    custom_fields: custom_fields || [],
    ...(isUserEssential && {
      dedicated,
      allow_overtime: isAllowOvertime,
      min_rate: min_rate.substring(1),
      max_rate: max_rate.substring(1),
    }),
    ...(Object.keys(recurring_attributes).length && { recurring_attributes }),
  };
};

export const createOrdersPayloadTransformer = ({
  groupedFormsValues,
  values,
  isUserEssential,
}: {
  groupedFormsValues: FormValuesType[];
  values: FormValuesType;
  isUserEssential?: boolean;
}) => {
  const createShifts = groupedFormsValues.map(
    (values) => values as unknown as CreateShiftFormValuesType,
  );
  return {
    order: {
      community_id: values.location.value,
      bookings: createShifts.map((shift) => shiftPayloadTransformer({ shift, isUserEssential })),
    },
  };
};

export const getPositionIdsFromAllShifts = (createShifts: ICreateShift[]) => {
  return createShifts
    .map((createShift) => castStringToInteger(createShift.values.position_id[0]))
    .filter((value, index, array) => array.indexOf(value) === index);
};
