import { AuthRoute } from 'guards/AuthRoute';
import { PublicRoute } from 'guards/PublicRoute';
import { useFeatureSelector } from 'guards/useFeatureSelector';
import {
  ADPAuthRedirectPage,
  CommunicationPage,
  CreateShiftsPage,
  DashboardPage,
  ForgotPage,
  LegacyAgencyTeamMembersPage,
  LoginPage,
  NotFoundPage,
  PendingPage,
  RegistrationPage,
  SchedulePage,
  ShiftsPage,
  TeamMemberPage,
  TimesheetsPage,
} from 'pages';
import SettingsPage from 'pages/SettingsPage/SettingsPage';
import { CustomFieldsPage } from 'pages/SettingsPage/CustomFieldsSettingsPage';
import { FC, ReactElement } from 'react';
import { SwitchProps } from 'react-router-dom';
import { AnimatedRoutes } from 'routes';
import { withMemo } from 'utils/withMemo';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { Version } from 'components/Version';

export const Routes: FC = withMemo(function _Routes(): ReactElement<SwitchProps> {
  const isReadOnly = useFeatureSelector('FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER');
  const isCustomFieldsEnabled = useFeatureSelector('FEATURE_TYPE_CUSTOM_FIELDS');
  const { isXtmProvider } = WhiteLabelAccessor;
  return (
    <AnimatedRoutes exitBeforeEnter initial={false}>
      <PublicRoute exact path="/forgot" component={ForgotPage} />
      <PublicRoute exact path="/login" component={LoginPage} />
      <PublicRoute exact path="/register" component={RegistrationPage} />
      <PublicRoute exact path="/version" component={Version} />
      <AuthRoute path="/communications" component={CommunicationPage} />
      <AuthRoute exact path="/pending" component={PendingPage} />
      {!isReadOnly && (
        <AuthRoute
          userType={[
            'USER_TYPE_B2B',
            'USER_TYPE_PREMIUM_SECONDARY',
            'USER_TYPE_ESSENTIAL_SECONDARY',
          ]}
          exact
          path={['/create-shifts', '/create-shift']}
          component={CreateShiftsPage}
        />
      )}
      {!isReadOnly && <AuthRoute exact path="/schedule" component={SchedulePage} />}
      {!isXtmProvider && (
        <AuthRoute
          userType={[
            'USER_TYPE_AGENCY',
            'USER_TYPE_B2B',
            'USER_TYPE_PREMIUM_SECONDARY',
            'USER_TYPE_ESSENTIAL_SECONDARY',
          ]}
          exact
          path="/team-member"
          component={TeamMemberPage}
        />
      )}
      {!isXtmProvider && (
        <AuthRoute
          userType={[
            'USER_TYPE_B2B',
            'USER_TYPE_PREMIUM_SECONDARY',
            'USER_TYPE_ESSENTIAL_SECONDARY',
          ]}
          exact
          path="/agency-member"
          component={LegacyAgencyTeamMembersPage}
        />
      )}
      <AuthRoute exact path="/auth" component={ADPAuthRedirectPage} />
      {isCustomFieldsEnabled && (
        <AuthRoute exact path="/settings/company" component={CustomFieldsPage} />
      )}
      <AuthRoute path="/settings" component={SettingsPage} />
      <AuthRoute exact path="/timesheets" component={TimesheetsPage} />
      <AuthRoute
        exact
        path={['/shifts', '/shifts/calendar', '/shifts/list']}
        component={ShiftsPage}
      />
      <AuthRoute path={['/', '/dashboard']} component={DashboardPage} />
      <AuthRoute component={NotFoundPage} />
    </AnimatedRoutes>
  );
});
