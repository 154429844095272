import { FC, createContext, useContext, useMemo } from 'react';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { IShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.types';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import {
  isMultiLocationCallOutFeatureEnabledSelector,
  isMultiLocationSchedulingFeatureEnabledSelector,
} from 'store/selectors/featureFlagSelectors';
import { useSelector } from 'react-redux';
import { getIsJaneCommunityMismatch } from 'utils/getIsJaneCommunityMismatch';

export const ShiftModalPropsContext = createContext<IShiftModalPropsContext>(
  {} as IShiftModalPropsContext,
);

export const ShiftModalPropsProvider: FC = ({ children }) => {
  const { data } = useShiftModalDataContext();

  const currentlySelectedLocation = getCurrentlySelectedLocation();

  const isMultiLocationCallOutFeatureEnabled = useSelector(
    isMultiLocationCallOutFeatureEnabledSelector(Number(currentlySelectedLocation?.value)),
  );
  const isMultiLocationSchedulingFeatureEnabled = useSelector(
    isMultiLocationSchedulingFeatureEnabledSelector(Number(currentlySelectedLocation?.value)),
  );

  const isSharedJanePrimaryCommunityDifferentFromSelectedLocation = useMemo(() => {
    if (data) {
      const { acceptedBySharedTeamMember, mainJane, status } = data.fetchOrder;
      return getIsJaneCommunityMismatch({
        acceptedBySharedTeamMember,
        status,
        mainJane,
        currentlySelectedLocation,
        isMultiLocationCallOutFeatureEnabled,
        isMultiLocationSchedulingFeatureEnabled,
      });
    } else return false;
  }, [
    data,
    currentlySelectedLocation,
    isMultiLocationCallOutFeatureEnabled,
    isMultiLocationSchedulingFeatureEnabled,
  ]);

  const contextValue = useMemo(
    () => ({
      isSharedJanePrimaryCommunityDifferentFromSelectedLocation,
      goToDeleteView: () => shiftModalRoutingService.goToView('DeleteView'),
      goToEditView: () => shiftModalRoutingService.goToView('EditView'),
      goToReleaseShiftView: () => shiftModalRoutingService.goToView('ReleaseShiftView'),
      goToSendToAgenciesView: () => shiftModalRoutingService.goToView('PreRequestOptionsView'),
      goToAwardTeamMemberView: () => shiftModalRoutingService.goToView('AwardTeamMemberView'),
      goToConfirmEditsView: () => shiftModalRoutingService.goToView('ConfirmEditsView'),
    }),
    [isSharedJanePrimaryCommunityDifferentFromSelectedLocation],
  );
  return (
    <ShiftModalPropsContext.Provider value={contextValue}>
      {children}
    </ShiftModalPropsContext.Provider>
  );
};

export const useShiftModalPropsContext = (): IShiftModalPropsContext =>
  useContext(ShiftModalPropsContext);
