import { BKJButton, BKJComboBox, BKJPillDropdown } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import ErrorLabel from 'components/ErrorLabel';
import { Flex } from 'components/Flex';
import { useGroupsModalFormContext } from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.form.context';
import {
  GroupDetailsHeaderBorderStyle,
  H3,
} from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.styled';
import {
  criteriaHoursDropdownOptionsPeriod,
  criteriaHoursDropdownOptionsDay,
  criteriaMinsDropdownOptions,
  positionTypes,
  isEmptyOrZero,
  EmploymentStatusesDropdownOptionLabelType,
  EmploymentStatusesDropdownOptionValueType,
  fetchDynamicGroupEmploymentStatusesConfig,
} from 'pages/SettingsPage/modals/GroupsModal/views/dynamic/SelectCriteria.constants';
import {
  CriteriaBody,
  CriteriaColumn,
  CriteriaSubHeader,
  SelectCriteriaWrapper,
} from 'pages/SettingsPage/modals/GroupsModal/views/dynamic/SelectCriteria.styled';
import { FC, FormEvent, Fragment, useEffect, useMemo } from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'hooks/useTranslation';
import { useSelector } from 'react-redux';
import { isEnhancedDynamicGroupsFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { useLocation } from 'react-router-dom';
import { fetchDynamicGroupEmploymentStatuses } from 'types/graphql-types';
import { useGQLQuery } from 'hooks';
import { startCase } from 'utils/startCase';
export const SelectCriteriaView: FC = () => {
  const { values, onChange, onSubmit, minPeriodValidation, minDayValidation, isSubmitting } =
    useGroupsModalFormContext();

  const {
    data: employmentStatusesValue,
    fetch,
    isLoading,
  } = useGQLQuery<
    fetchDynamicGroupEmploymentStatuses,
    fetchDynamicGroupEmploymentStatuses,
    undefined
  >(fetchDynamicGroupEmploymentStatusesConfig());

  useEffect(() => {
    if (!employmentStatusesValue && isLoading) fetch();
  }, [fetch, isLoading, employmentStatusesValue, employmentStatusesValue?.fetchMetadata]);

  const dynamicGroupDropdownOptions = useMemo(() => {
    return employmentStatusesValue
      ? employmentStatusesValue.fetchMetadata.dynamicGroupCriteriaEmploymentStatuses.map(
          (status) => ({
            id: status.id,
            key: status.id,
            label: startCase(status.displayText) as EmploymentStatusesDropdownOptionLabelType,
            value: status.id as EmploymentStatusesDropdownOptionValueType,
            name: 'employmentStatuses',
          }),
        )
      : [];
  }, [employmentStatusesValue]);
  const { pathname } = useLocation();
  const location_id = Number.parseInt(pathname.split('/')[3]);
  const isEnhancedDynamicGroupsFeatureEnabled = useSelector(
    isEnhancedDynamicGroupsFeatureEnabledSelector(location_id),
  );

  const isFormValuesEmpty = useMemo(
    () =>
      values.employmentStatuses.length === 0 &&
      values.positionTypes.length === 0 &&
      isEmptyOrZero(values.minimumHoursPeriod) &&
      isEmptyOrZero(values.minimumMinsPeriod) &&
      isEmptyOrZero(values.maximumHoursPeriod) &&
      isEmptyOrZero(values.maximumMinsPeriod) &&
      isEmptyOrZero(values.minimumHoursDay) &&
      isEmptyOrZero(values.minimumMinsDay) &&
      isEmptyOrZero(values.maximumHoursDay) &&
      isEmptyOrZero(values.maximumMinsDay),
    [
      values.employmentStatuses.length,
      values.maximumHoursDay,
      values.maximumHoursPeriod,
      values.maximumMinsDay,
      values.maximumMinsPeriod,
      values.minimumHoursDay,
      values.minimumHoursPeriod,
      values.minimumMinsDay,
      values.minimumMinsPeriod,
      values.positionTypes.length,
    ],
  );

  const isDisabled = useMemo(
    () => isFormValuesEmpty || minPeriodValidation || minDayValidation || isSubmitting,
    [isFormValuesEmpty, isSubmitting, minDayValidation, minPeriodValidation],
  );

  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
      borderTop={GroupDetailsHeaderBorderStyle}
    >
      <SelectCriteriaWrapper>
        <H3 paddingBottom="28px">{t('groups:SELECT_CRITERIA')}</H3>

        <CriteriaBody>{t('groups:SELECT_CRITERIA_DESCRIPTION')}</CriteriaBody>

        <CriteriaSubHeader>{t('groups:CRITERIA_FOR_TEAM_MEMBER')}</CriteriaSubHeader>

        <CriteriaBody>{t('groups:EMPLOYMENT_STATUS')}</CriteriaBody>
        <Flex height="60px">
          <BKJPillDropdown
            options={dynamicGroupDropdownOptions}
            name="employmentStatuses"
            onChange={onChange}
            value={values.employmentStatuses}
            validations={[]}
            max={3}
            placeholder={t('groups:EMPLOYMENT_STATUS_PLACEHOLDER')}
          />
        </Flex>

        <CriteriaBody
          css={css`
            padding-top: 10px;
          `}
        >
          {t('groups:POSITION_TYPE')}
        </CriteriaBody>
        <Flex height="60px">
          <BKJPillDropdown
            options={positionTypes()}
            name="positionTypes"
            onChange={onChange}
            value={values.positionTypes}
            validations={[]}
            max={2}
            placeholder={t('groups:POSITION_TYPE_PLACEHOLDER')}
          />
        </Flex>

        {isEnhancedDynamicGroupsFeatureEnabled && (
          <Fragment>
            <CriteriaSubHeader>{t('groups:CRITERIA_FOR_HOURS_WORKED_PERIOD')}</CriteriaSubHeader>
            <Flex flexDirection="row">
              <CriteriaColumn colspan={2}>{t('groups:MINIMUM')}</CriteriaColumn>
              <CriteriaColumn colspan={2}>{t('groups:MAXIMUM')}</CriteriaColumn>
            </Flex>
            <Flex>
              <CriteriaColumn>
                <BKJComboBox
                  type="Single"
                  value={values.minimumHoursPeriod}
                  options={criteriaHoursDropdownOptionsPeriod}
                  name="minimumHoursPeriod"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  placeholder="Hours"
                  onChange={onChange}
                  error={minPeriodValidation}
                  $dropdownHeight="150px"
                />
              </CriteriaColumn>
              <CriteriaColumn>
                <BKJComboBox
                  type="Single"
                  value={values.minimumMinsPeriod}
                  options={criteriaMinsDropdownOptions}
                  name="minimumMinsPeriod"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  placeholder="Mins"
                  onChange={onChange}
                  error={minPeriodValidation}
                />
              </CriteriaColumn>
              <CriteriaColumn>
                <BKJComboBox
                  type="Single"
                  value={values.maximumHoursPeriod}
                  options={criteriaHoursDropdownOptionsPeriod}
                  name="maximumHoursPeriod"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  placeholder="Hours"
                  onChange={onChange}
                  error={minPeriodValidation}
                  $dropdownHeight="150px"
                />
              </CriteriaColumn>
              <CriteriaColumn>
                <BKJComboBox
                  type="Single"
                  value={values.maximumMinsPeriod}
                  options={criteriaMinsDropdownOptions}
                  name="maximumMinsPeriod"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  placeholder="Mins"
                  onChange={onChange}
                  error={minPeriodValidation}
                />
              </CriteriaColumn>
            </Flex>

            <ErrorLabel
              css={css`
                font-size: 14px;
              `}
              isVisible={minPeriodValidation}
              message={t('groups:MINIMUM_HOURS_ERROR')}
            />

            <CriteriaSubHeader>{t('groups:CRITERIA_FOR_HOURS_WORKED_DAY')}</CriteriaSubHeader>
            <Flex flexDirection="row">
              <CriteriaColumn colspan={2}>{t('groups:MINIMUM')}</CriteriaColumn>
              <CriteriaColumn colspan={2}>{t('groups:MAXIMUM')}</CriteriaColumn>
            </Flex>

            <Flex>
              <CriteriaColumn>
                <BKJComboBox
                  type="Single"
                  value={values.minimumHoursDay}
                  options={criteriaHoursDropdownOptionsDay}
                  name="minimumHoursDay"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  placeholder="Hours"
                  onChange={onChange}
                  error={minDayValidation}
                  allowedAutoPlacements={['top']}
                  $dropdownHeight="150px"
                />
              </CriteriaColumn>
              <CriteriaColumn>
                <BKJComboBox
                  type="Single"
                  value={values.minimumMinsDay}
                  options={criteriaMinsDropdownOptions}
                  name="minimumMinsDay"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  placeholder="Mins"
                  onChange={onChange}
                  error={minDayValidation}
                  allowedAutoPlacements={['top']}
                />
              </CriteriaColumn>
              <CriteriaColumn>
                <BKJComboBox
                  type="Single"
                  value={values.maximumHoursDay}
                  options={criteriaHoursDropdownOptionsDay}
                  name="maximumHoursDay"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  placeholder="Hours"
                  onChange={onChange}
                  error={minDayValidation}
                  allowedAutoPlacements={['top']}
                  $dropdownHeight="150px"
                />
              </CriteriaColumn>
              <CriteriaColumn>
                <BKJComboBox
                  type="Single"
                  value={values.maximumMinsDay}
                  options={criteriaMinsDropdownOptions}
                  name="maximumMinsDay"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  placeholder="Mins"
                  onChange={onChange}
                  error={minDayValidation}
                  allowedAutoPlacements={['top']}
                />
              </CriteriaColumn>
            </Flex>

            <ErrorLabel
              css={css`
                font-size: 14px;
              `}
              isVisible={minDayValidation}
              message={t('groups:MINIMUM_HOURS_ERROR')}
            />
          </Fragment>
        )}
      </SelectCriteriaWrapper>
      <Flex height="100%"></Flex>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          onClick={(e) => onSubmit(e as unknown as FormEvent<HTMLFormElement>)}
          type="submit"
          disabled={isDisabled}
        >
          {t('groups:SAVE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const SelectCriteria: FC = () => {
  return <SelectCriteriaView />;
};
