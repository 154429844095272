import { useURLParameters } from 'hooks';
import {
  ShiftDistributionComponentMap,
  ShiftDistributionModalViewType,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.constants';
import { createContext, FC, useCallback, useContext, useEffect } from 'react';
import { ModalRoutingService } from 'services';

export const tieringDetailsModalRoutingService = new ModalRoutingService(
  'tieringDetails',
  'TieringDetails',
  ShiftDistributionComponentMap,
  {},
  ['TieringDetails', 'SharingDetails'],
);

export interface ITieringDetailslUI {
  isOpen: boolean;
  onClose: () => void;
  tieringDetailsActiveModalView: ShiftDistributionModalViewType;
}

export const TieringDetailsViewUIContext = createContext<ITieringDetailslUI>(
  {} as ITieringDetailslUI,
);

export const TieringDetailsViewUIProvider: FC = ({ children }) => {
  const [{ tieringDetailsActiveModalView }, { removeParam }] =
    useURLParameters<{ activeModalView: ShiftDistributionModalViewType }>();

  const handleClose = useCallback(() => {
    tieringDetailsModalRoutingService.close();
  }, []);

  useEffect(() => {
    if (!Object.keys(ShiftDistributionComponentMap).includes(tieringDetailsActiveModalView)) {
      removeParam(['tieringDetailsActiveModalView']);
    }
  }, [tieringDetailsActiveModalView, removeParam]);

  return (
    <TieringDetailsViewUIContext.Provider
      value={{
        isOpen: !!tieringDetailsActiveModalView,
        onClose: handleClose,
        tieringDetailsActiveModalView:
          tieringDetailsActiveModalView as ShiftDistributionModalViewType,
      }}
    >
      {children}
    </TieringDetailsViewUIContext.Provider>
  );
};

export const useTieringDetailsModalUIContext = () =>
  useContext<ITieringDetailslUI>(TieringDetailsViewUIContext);
